import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';
import { getToken, getAuthorizeUrl } from "@/utils";
import { getMenu, realLogin } from '@/api/login'
import { message } from 'ant-design-vue';

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === 'production' || process.env.VUE_APP_BUILD == '1' ? '/' : undefined),
  routes: [
    {
      path: "/login",
      name: "loginPage",
      meta: { title: "loginPage" },
      component: () => import("@/views/login/index.vue"),
    },
    {
      path: "/",
      name: "homePage",
      meta: { title: "home" },
      component: () => import("@/views/home/index.vue"),
      children: [
        {
          path: "notice",
          meta: { title: "系统通知" },
          component: () => import("@/views/notice/system/index.vue"),
        },
        // 产研排期
        {
          path: "/RDManage/scheduling/list",
          meta: { title: "产研排期列表" },
          component: () => import("@/views/RDManage/scheduling/list.vue"),
        },
      ]
    },
    // 团队账单详情
    {
      path: "/billManage/billDetail",
      name: "billDetail",
      meta: { title: "billDetail", hideInMenu: true },
      component: () => import("@/views/billManage/billDetail.vue"),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'errorPage',
      meta: { hideInMenu: true },
      component: () => import('@/views/error/404.vue')
    }
  ],
});

//处理路由数据
const getRouter = () => {
  const routers = store.getters.router || [];
  const newRouter = [];
  routers.map((v) => {
    if (v.component) {
      let permission = {};
      routers.filter(item => item.pid == v.id).map(item => {
        permission[item.permission.split(':')[1]] = true;
      });
      newRouter.push({
        path: v.path,
        name: v.name || v.path.slice(1),
        component: () => import(`@/${v.component}`),
        meta: { ...permission, title: v.title }
      });
    }
  });
  return newRouter;
};

let doAddRoute = false;
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (to.query.authCode) {
    const authCode = 'Basic' + " " + btoa(to.query.authCode);
    store.dispatch('Login', authCode).then((res) => {
      let query = { ...to.query };
      delete query.code;
      delete query.authCode;
      delete query.state;
      router.replace({ path: to.path, query });
    }).catch(err => {
      message.error(err.errorMsg || '操作失败');
    });
  } else if (!token) {
    window.location.href = getAuthorizeUrl(to.fullPath);
    //next("/login");
  } else if (token && to.path == '/login') {
    next('/');
  } else if (token && !doAddRoute) {
    console.log('组装路由');
    doAddRoute = true;
    store.dispatch('GetMenu').then(data => {
      const addRouter = getRouter();
      addRouter.length && addRouter.map((v) => router.addRoute('homePage', v));
      if(to.fullPath == '/' && addRouter.some(item => item.path == '/home')) {
        next('/home')
      }else {
        next(to.fullPath);
      }
    }).catch((error) => {
      console.log(error)
      message.error('获取菜单失败');
      setTimeout(() => {
        next("/login");
      }, 1500);
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  console.log("routerAfterEach");
});

export default router;
