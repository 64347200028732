// 配置 开发环境、生产环境baseurl地址
const CONFIG = {
  // 开发环境配置
  development: {
    baseUrl: "http://192.168.2.230:8095",
    ssoBaseUrl: "http://192.168.2.230:8095",
    // ssoBaseUrl: "http://192.168.2.230:10010",
    client_id:'dinglqfw6bdsq6x93ukk',
    isDev: true,
  },
  // 测试环境配置
  test: {
    baseUrl: "http://192.168.2.232:8095",
    ssoBaseUrl: "http://192.168.2.232:8095",
    client_id:'ding4d5n3qma1p4qcljg',
    isDev: true,
  },
  testUAT: {
    baseUrl: "http://192.168.2.235:8095",
    ssoBaseUrl: "http://192.168.2.235:8095",
    client_id:'ding4d5n3qma1p4qcljg',
    isDev: true,
  },
  // 生产环境配置
  production: {
    baseUrl: "https://it.fujiekeji.com.cn",
    ssoBaseUrl: "https://internalauth.fujiekeji.com.cn",
    client_id:'dingag0s0q1gmruqsxxz',
    isDev: false,
  },
};
//export default CONFIG[process.env.VUE_APP_ENV || process.env.NODE_ENV]

module.exports = CONFIG[process.env.VUE_APP_ENV || process.env.NODE_ENV];
