<template>
  <div v-if="visible" class="password-modal-container">
    <div class="modal-content" tabindex="0" hidefocus="true">
      <div class="model-title">重置密码</div>
      <div class="modal-form">
        <div class="modal-form-item">为了保障您的账号安全请按照要求修改密码，修改成功后可正常使用平台功能。</div>
        <div class="modal-form-item">
          <p>1、密码长度应不少于12位；</p>
          <p>2、密码应包含数字、大写字母、小写字母、符号（!、@、#、三种）4类；</p>
        </div>
        <a-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <a-form-item label="新密码" name="newPassword">
            <a-input-password type="password" v-model:value="ruleForm.newPassword" show-password autocomplete="off"
              placeholder="不少于12位，须含大小写字母、数字、特殊字符(！、@、#)"></a-input-password>
          </a-form-item>
          <a-form-item label="确认密码" name="duplicatePassword">
            <a-input-password type="password" v-model:value="ruleForm.duplicatePassword" autocomplete="off" show-password
              placeholder="不少于12位，须含大小写字母、数字、特殊字符(！、@、#)"></a-input-password>
          </a-form-item>
          <a style="text-align: right; margin-top: 30px;">
            <a-button type="primary" @click="submitForm('ruleForm')">确定</a-button>
          </a>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { resetPassword } from "@/api/login";
import { encryptData } from '@/utils/index';
import router from '@/router/index'
import { message } from 'ant-design-vue';
export default {
  name: 'password-modal-container',
  props: {
    title: String,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        return Promise.reject('请输入新密码');
      } else {
        if (value.length < 12) {
          return Promise.reject('密码不足12位');
        }
        if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#])[A-Za-z\d!@#]{12,}$/.test(value)) {
          return Promise.reject('密码不满足复杂度');
        }
        if (this.ruleForm.duplicatePassword) {
          this.$refs.ruleForm.validateFields(['duplicatePassword']);
        }
        return Promise.resolve();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        return Promise.reject('请再次输入密码');
      } else if (value !== this.ruleForm.newPassword) {
        return Promise.reject('两次输入密码不一致');
      } else {
        return Promise.resolve();
      }
    };
    return {
      visible: true,
      ruleForm: {
        newPassword: '',
        duplicatePassword: '',
      },
      rules: {
        newPassword: [
          { required: true, validator: validatePass, trigger: ['change', 'blur'] }
        ],
        duplicatePassword: [
          { required: true, validator: validatePass2, trigger: ['change', 'blur'] }
        ],
      }
    }
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    closeModal() {
      this.$emit('closed');
      document.removeEventListener('keydown', this.handleGlobalKeydown);
    },
    submitForm() {
      let ruleFormParams = { ...this.ruleForm }
      let that = this;
      this.$refs.ruleForm.validate().then(async (valid) => {
        if (valid) {
          ruleFormParams.newPassword = encryptData(ruleFormParams.newPassword)
          ruleFormParams.duplicatePassword = encryptData(ruleFormParams.duplicatePassword)
          try {
            return
            let res = await resetPassword(ruleFormParams);
            if (router.currentRoute.fullPath == '/notice') {
              router.push({
                path: '/notice',
                query: { refresh: Date.now() }
              })
            } else {
              window.location.href = window.location.origin
            }
            message.success('重置成功');
            that.closeModal();
          } catch (error) {
            message.error(error.msg);
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    openModel() {
      document.addEventListener('keydown', this.handleGlobalKeydown);
      let doc=document.getElementsByClassName('password-modal-container')[0];
      setTimeout(() => {
        doc.childNodes[0].focus();
      }, 300);
    },
    handleGlobalKeydown(event){
      if (event.key === 'Escape' || event.keyCode === 27) { 
        event.stopPropagation();
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.password-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* 设置较高的z-index，确保弹框覆盖其他元素 */
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 200px auto;
  padding: 20px;
  .model-title {
    font-size: 20px;
    font-weight: bold;
  }
}
.modal-content:focus {
  outline: none;
}
.modal-form {
  margin-left: 70px;
  .modal-form-item {
    margin-top: 20px;
  }
}
.demo-ruleForm {
  width: 80%;
  margin: 20px 0;
}

::v-deep .el-form-item__label {
  color: #000;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>