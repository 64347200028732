<template>
   <a-cascader
      :options="option ? option : props.options"
      expand-trigger="hover"
      :value="value"
      @change="change"
      allowClear
      :maxTagCount="3"
      class="my-cascader"
  />
</template>
<script setup>
  import { ref, defineProps, defineEmits, watchEffect, watch } from 'vue';
  const props = defineProps({
    modelValue: {},
    url: {
      type: [Function], 
    },
    params: {
      type: [Object],
      default: () => ({})
    },
    onSetOptions: {
      type: [Function],
    },
    options: {
      type: [Array],
      default: () => []
    },
    value1: {
      type: [String]
    },
    value2: {
      type: [String]
    },
    value3: {
      type: [String]
    },
    //表单嵌入弹出框时候使用 使得每次打开弹窗时重新调用接口来获取数据
    show: {
      type: [Boolean],
      default: true,
    }
  });

  const emits = defineEmits(['update:modelValue', 'update:value1', 'update:value2', 'update:value3', 'change']);
  const value = ref([]);
  const option = ref(false);
  const change = (value, selectedOptions) => {
    emits('change', value, selectedOptions);
    emits('update:modelValue', value);
    emits('update:value1', (value && value[0]) ? value[0] : undefined)
    emits('update:value2', (value && value[1]) ? value[1] : undefined)
    emits('update:value3', (value && value[2]) ? value[2] : undefined)
  }
  const init = (fun = props.url, params = props.params) => {
    fun(params).then(res => {
      if (props.onSetOptions) {
        option.value = props.onSetOptions(res);
      } else {
        option.value = res.data.records ? res.data.records : res.data;
      }
    })
  };
  watch(() => props.modelValue, v => {
    value.value = v || [];
  }, { immediate: true });
  watchEffect(() => {
    if(!!props.value1) {
      value.value[0] = props.value1
    }else {
      value.value.length = 0;
      return;
    }
    if(!!props.value2) {
      value.value[1] = props.value2
    }else {
      value.value.length = 1;
      return;
    }
    if(!!props.value3) {
      value.value[2] = props.value3
    }else {
      value.value.length = 2;      
    }
  });
  watchEffect(() => {
    props.show && props.url && init(props.url, props.params);
  });

  // 向父级抛出的属性 方法
  defineExpose({
    init,
  });

</script>
<style scoped>
.my-cascader{
  min-width: 260px;
}
</style>
