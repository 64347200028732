<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  width: {
    type: String,
    default: "440px",
  },
  height: {
    type: String,
    default: "380px",
  },
  colorList: {
    type: Array,
    default: () => ['#3671FE', '#18D991', '#36CDF3', '#F6BD16', '#FF8A55', '#F64E16'],
  }
});
const option = ref({});
const initData = () => {
  option.value = {
    color: props.colorList,
    dataset: {
      dimensions: ['product', 'count', 'rate'],
      source: props.dataSource,
    },
    series: [
    {
      name: 'funnel',
      type: 'funnel',
      sort: 'none', 
      left: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      minSize: '0%',
      label: {
        show: true,
        position: 'inside',
        formatter(params){
          return  params.data.product + params.data.count 
        },
        color: '#fff'
      },
    }
    ],
  };
};
watch(() => props.dataSource, () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>