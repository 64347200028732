import request from '@/utils/request'

//获取钉钉authCode后登录
export function realLogin(authCode) {
  let headers ={
    Authorization:authCode,
    'login-type':'ding_ding',
  };
  if(process.env.NODE_ENV != "production" && process.env.VUE_APP_BUILD != '1'){
    headers['X-Forwarded-Host'] = 'forj.dev.com';
  }
  return request({
    url: '/internal-auth/login',
    method: 'post',
    headers:{
      ...headers
    },
  })
}

export function userInfo(data) {
  return request({
    url: '/internal-auth/getUserInfoByToken',
    method: 'post',
    data: data
  })
}

export function getVerificationCode() {
  return request({
    url: '/security/obtain-graph-captcha',
    method: 'get'
  })
}

export function login (data) {
  return request({
    url: '/login.json',
    // url: '/login/username_password',
    method: 'post',
    data: data
  })
}

export function getMenu(data) {
  return request({
    //url: '/menu.json',
    url: '/internal-auth/menu/queryAccessibleMenus',
    method: 'post',
    data
  })
}

