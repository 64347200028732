<template>
  <!-- 上传 -->
  <my-modal title="支持扩展名：pdf、jpg、png、xls、xlsx" v-model="visibleUpload" :bodyStyle="{ paddingRight: '58px' }" :width="650">
    <a-spin :spinning="spinning" tip="加载中...">
      <div style="padding-bottom: 20px;">
        <input ref="fileInput" type="file" @change="batchUploadFun" :accept="accept" multiple style="display: none;"/>
        <a-button type="primary" @click="$refs.fileInput.click()" :loading="loading">
          <upload-outlined></upload-outlined>
          批量上传
        </a-button>
      </div>
      <div>
        <div class="card" v-for="(item, index) in configList">
          <div class="left">
            <div>{{ item.coordFieldName }}</div>
            <my-upload v-model="item.fileList" 
                       :accept="accept"
                       immediately
                       :upload="uploadOne" 
                       :max="item.limitCount" 
                       :data="{ applyId: applyId, bizName: props.bizName, coordField: item.coordField }"
                       @afterUpload="open()"
                       @afterDelete="deleteImg"
                       :showUploadList="{ showRemoveIcon: !item.locked }">
            </my-upload>
          </div>
          <div class="right">
            <my-switch v-model="item.locked" @change="e=>switchChange(e, item)"/>
          </div>
        </div>
      </div>
    </a-spin>
    <template #footer>
    </template>
  </my-modal>
</template>

<script setup>
  import { ref, defineProps, computed, defineComponent } from 'vue';
  import { getFileList, uploadOne, fileLocked, fileDelete, batchUpload } from '@/api/public'
  import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';

  const props = defineProps({
    bizName: {
      type: String,
      default: ''
    }
  });
  const accept = ref('image/*,.pdf,.xls,.xlsx');
  // 上传
  const spinning = ref(false);
  const visibleUpload = ref(false);
  const configList = ref([])
  const applyId = ref('');
  const open = (id = '') => {
    id && (applyId.value = id);
    getFileList({ applyId: applyId.value, bizName: props.bizName }).then(res => {
      configList.value = res.data
    }).catch((e) => {
      message.error('获取附件列表失败！');
    })
    visibleUpload.value = true;
  }

  const deleteImg = item => {
    fileDelete([item.id]).then(res => {
      message.success('删除成功！');
    }).catch((e) => {
      message.error('删除失败！');
    }).finally(() => {
      open();
    })
  }

  const switchChange = (locked, item) => {
    const { id, coordField } = item;
    fileLocked({ id, coordField, applyId: applyId.value, bizName: props.bizName, locked }).then(res => {
      message.success(`${locked ? '锁定' : '解锁'}成功！`);
    }).catch((e) => {
      item.locked = !locked;
      message.error(`${locked ? '锁定' : '解锁'}失败！`);
    })
  }

  const loading = ref(false);
  const fileInput = ref();
  const batchUploadFun = (e) => {
    let fileList = e.target.files;
    let max = 0;
    configList.value.map(v => {
      max += (v.limitCount - v.fileList.length);
    });
    const formData = new FormData();
    for (let i = 0; i < max; i++) {
      if (fileList[i] && accept.value && !new RegExp(`.(${accept.value.replace(/,/g, '|').replace(/image\/\*/g, 'png|jpg|gif|jpeg|webp').replace(/image\//g, '')})$`).test(fileList[i].type + fileList[i].name)) {
        message.error('只能上传： ' + accept.value.replace(/image\/\*,/g, '图片,').replace(/,image\/\*/g, ',图片').replace(/image\//g, '').replace(/,/g, '或') + '格式的文件');
        return false;
      }
      fileList[i] && formData.append('file', fileList[i]);
    }
    formData.append('bizName', props.bizName);
    formData.append('applyId', applyId.value);
    loading.value = true; // You can use any AJAX library you like
    batchUpload(formData).then(res => {
      message.success('上传成功');
    }).catch(() => {
      message.error('上传失败');
    }).finally(() => {
      open();
      fileInput.value.value = '';
      loading.value = false;
    });
  }

  //向父级抛出的属性 方法
  defineExpose({
    open
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .card {
    width: 600px;
    min-height: 120px;
    border: 1px solid rgba(121, 121, 121, 0.3);
    display: flex;
  }
  .left {
    width: 80%;
    min-height: 120px;
    padding: 10px;
    border-right: 1px solid rgba(121, 121, 121, 0.3);
  }
  .right {
    width: 20%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
