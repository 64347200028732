import { request, download } from '@/utils/request'

const base = "efficiency";
export function upload (data) {
  return request({
    url: '/oss/upload',
    //url: '/upload.json',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 60000,
    data: data
  })
}

// 文件列表 上传
export function getFileList(data) {
  return request({
    url: `/sys-file-ref/file-upload-list`,
    method: 'get',
    params: data
  })
}

// 文件列表 下载
export function getFileDownloadList(data) {
  return request({
    url: `/sys-file-ref/file-list`,
    method: 'get',
    params: data
  })
}

// 上传
export function uploadOne(data) {
  return request({
    url: `/sys-file-ref/file-upload`,
    method: 'post',
    data: data
  })
}

// 批量上传
export function batchUpload(data) {
  return request({
    url: `/sys-file-ref/full-file-upload`,
    method: 'post',
    data: data
  })
}

// 删除文件
export function fileDelete(data) {
  return request({
    url: `/sys-file-ref/file-batch-delete`,
    method: 'post',
    data: data
  })
}

// 锁定文件
export function fileLocked(data) {
  return request({
    url: `/sys-file-ref/file-locked`,
    method: 'post',
    data: data
  })
}

// 下载文件
export function attachmentDownload(data) {
  return download({
    url: `/sys-file-ref/file-download`,
    method: 'post',
    data: data
  })
}

//查询产品研发部下所有用户信息
export function queryProductDeptUser(data) {
  return request({
    url:`${base}/dingUser/query-product-dept-user`,
    method: 'post',
    data: data
  })
}

//查询用户列表
export function getDingUserList(data) {
  return request({
    url:`${base}/dingUser/get-list`,
    method: 'post',
    data: data
  })
}
