<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => []
  },
  legendData: {
    type: Array,
    default: () => []
  },
  titleData: {
    type: Array,
    default: () => []
  },
  unitData: {
    type: Array,
    default: () => ['万', '%']
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: '360px'
  },
  height: {
    type: String,
    default: '320px'
  }
})
const option = ref({});
const initData = () => {
  option.value = {
    dataset: {
      dimensions: ['product', 'count', 'rate'],
      source: props.dataSource
    },
    grid: {
      bottom: "12%",
      left: '15%',
      right: '15%'
    },
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line"
      },
      formatter: (params) => { 
        let paramsData = params.map((param, index) => {
          const value = param.value[index == 0 ? 'count' : 'rate'];
          const unit = props.unitData[index];
          return `${param.marker}${param.seriesName}: ${value}${unit}<br>`;
        }).join('');       
        return `${params[0].value.product}<br>${paramsData}`
      }
    },
    color: ["#3AADFF", "#40FFCB"],
    legend: {
      show: props.showLegend,
      data: props.legendData,
      left: "center",
      top: "10%",
      itemWidth: 30,
      itemHeight: 11,
      textStyle: {
        color: "rgba(0, 0, 0, 0.25)",
        fontSize: 12,
        fontStyle: 'italic',
      },
    },
    toolbox: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: "rgba(0, 0, 0, 0.25)",
            width: 1
          },
        },
        axisLabel: {
          textStyle: {
            color: "rgba(0, 0, 0, 0.45)",
            fontSize: 12,
            fontStyle: "italic",
          },
        },
      },
    ],
    yAxis: [
      {
        name: props.titleData[0],
        nameTextStyle: {
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: 12,
          padding: [0, 40, 0, 0]
        },
        type: "value",
        axisLine: {
          show: false,
        },
        splitNumber: 4,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(0, 0, 0, 0.25)",
            width: 1,
            type: "dashed",
          },
        },
        axisLabel: {
          fontSize: 14,
          fontStyle: "italic",
          color: "rgba(0, 0, 0, 0.45)",
          formatter: "{value}" + props.unitData[0],
        },
      },
      {
        name: props.titleData[1],
        nameTextStyle: {
          color: "rgba(0, 0, 0, 0.45)",
          fontSize: 12,
          padding: [0, 0, 0, 50]
        },
        type: "value",
        axisLine: {
          show: false,
        },
        splitNumber: 4,
        axisTick: {
          show: false,
        },
        axisLabel: {
          // 纵坐标数值
          fontSize: 14,
          fontStyle: "italic",
          color: "rgba(0, 0, 0, 0.45)",
          formatter: "{value}" + props.unitData[1],
        },
        splitLine: {
          // 纵坐标间隔线
          show: false,
          lineStyle: {
            color: "rgba(0, 0, 0, 0.25)",
            width: 1,
            type: "dashed",
          },
        },
      },
    ],
    series: [
      {
        name: props.legendData[0],
        type: "line",
        // smooth: true,
        // symbol: "none",
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: "rgba(35, 136, 253, 1)",
              },
              {
                offset: 1,
                color: "rgba(35, 136, 253, 0.25)",
              },
            ],
            global: false,
          },
        },
        yAxisIndex: 0,
      },
      {
        name: props.legendData[1],
        type: "line",
        // smooth: true,
        yAxisIndex: 1,
        color: "#40ffcb",
        // symbol: "none",
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: "rgba(99, 218, 171, 1)",
              },
              {
                offset: 1,
                color: "rgba(99, 218, 171, 0.25)",
              },
            ],
            global: false,
          },
        },
        z: 10,
      },
    ],
  };
}
watch(() => props.dataSource, () => 
{
  initData();
}, { immediate: true, deep: true })
defineExpose({
  option
})
</script>
