<template>
  <a-form :model="formList"
          ref="formRef"
          name="myForm"
          :label-col="{ span: $attrs.hideLable ? 0 : 4 }"
          :wrapper-col="{ span: $attrs.hideLable ? 24 : 20 }"
          autocomplete="off"
          :validate-messages="validateMessages"
          :class="[props.width <= 700 ? 'smallForm' : 'largeForm', 'myForm']">
    <component :is="$attrs.layout=='inline' ? 'div' : 'a-row'" :class="{'formInline': $attrs.layout=='inline'}">
      <template v-for="(item, i) in props.form">
        <component v-show="runShow(showObj[item.model]) || (item.title && showObj[item.model] !== false)" :is="$attrs.layout=='inline' ? 'div' : 'a-col'" :span="item.span||24" :class="{ 'formInlineChild': $attrs.layout=='inline' }" :style="{ 'flex-basis': item.wrap ? '100%' : '' } ">
          <div v-if="item.title&&(!item.model || runShow(showObj[item.model]))&&(!item.isApproval || openType == 'approval' || openType == 'preview')" :key="'formItem' + i" class="formItemTitle">
            <div class="columnIcon"></div>
            {{ item.title }}
          </div>
          <a-form-item v-else
                        v-show="runShow(showObj[item.model])"
                       :id="item.model"
                       :key="'formItem' + i"
                       :name="setFormItemName(item)"
                       :required="setRequired(item)"
                       :label="$attrs.hideLable || item.useLableSlot ? null : setLabel(item)"
                       :rules="item.rules ? item.rules : []"
                       :autoLink="!setType(item) || setType(item).indexOf('my-upload') == -1"
                       :label-col="getItemLabelCol(item, $attrs.layout)"
                       :wrapper-col="getItemWrapperCol(item, $attrs.hideLable ? 'hideLable' : $attrs.layout)">
            <template #label v-if="$attrs.layout!='inline'">
              <span v-html="setLabel(item)"></span>
            </template>
            <slot v-if="item.slot" :item="item" :form="formList" :show="show" :disabled="disabled[item.model]" :openType="props.openType"></slot>
            <!-- v-model="formList[item.model]" -->
            <component v-else-if="!item.slot && typeof(item.model) == 'string'"
                       :modelValue="getProperty(formList, item.model)"
                       @update:modelValue="setProperty(formList, item.model, $event)"
                       :ref="e => e && !myNodes.some(v=>v==e) && myNodes.push(e)"
                       :is="setType(item)"
                       :placeholder="setPlaceholder(setType(item), setLabel(item))"
                       :show="show"
                       :openType="props.openType"
                       v-bind="item.attrs||{}"
                       :disabled="disabled[item.model]"></component>
            <!-- v-model:value1="formList[item.model[0]]" -->
            <component v-else-if="!item.slot && Array.isArray(item.model)"
                       :value1="getProperty(formList, item.model[0])"
                       @update:value1="setProperty(formList, item.model[0], $event)"
                       :value2="getProperty(formList, item.model[1])"
                       @update:value2="setProperty(formList, item.model[1], $event)"
                       :value3="getProperty(formList, item.model[2])"
                       @update:value3="setProperty(formList, item.model[2], $event)"
                       :ref="e => e && !myNodes.some(v=>v==e) && myNodes.push(e)"
                       :is="setType(item)"
                       :placeholder="setPlaceholder(setType(item), item.label)"
                       :show="show"
                       :openType="props.openType"
                       v-bind="item.attrs||{}"
                       :disabled="disabled[item.model]"></component>
          </a-form-item>
        </component>
      </template>
      <slot name="footer">
        <a-form-item v-if="showSubmit" :wrapper-col="{ offset: 8, span: 16 }">
          <my-button @click="submit(openType!='update')" :loading="submitLoading">提交</my-button>
          <my-button type="rest" @click="resetFields();clearValidate()"></my-button>
        </a-form-item>
      </slot>
    </component>
  </a-form>
</template>

<script setup>
  import { ref, reactive, defineProps, defineEmits, defineExpose, watchEffect, watch } from 'vue';
  import { setPlaceholder, getProperty, setProperty } from "@/utils";
  import { message } from 'ant-design-vue';
  import validateMessages from './validateMessages.js'//表单验证提示
  
  const props = defineProps({
    form: {
      type: Array,
      default: () => []
    },
    show: {
      type: [Boolean],
      default: true,
    },
    showSubmit: {
      type: [Boolean],
      default: true,
    },
    whiteList: {
      type: [Array, Boolean],
      default: () => ['id'],
    },
    openType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 700
    },
  });
  const emits = defineEmits(['submit']);
  const setLabel = (item) => {
    if (typeof item.label == 'function') {
      return item.label(formList.value);
    } else if (Array.isArray(item.label) && item.label.length > item.model.length) {
      return item.label.slice(-1);
    } else if (Array.isArray(item.label) && item.label.length == item.model.length) {
      return item.label[0];
    } else {
      return item.label;
    }
  }
  const getItemLabelCol = (item, layout) => {
    let span = 4;
    if (props.width >= 1600) {
      span = 2;
    } else if (props.width >= 1200) {
      span = 3;
    }
    if (layout == 'hideLable') {
      return { span: 0 }
    } else if (layout == 'inline') {
      return { }
    } else if (item.labelCol) {
      return item.labelCol;
    } else if (!item.label) {
      return { span: 0 }
    } else if (item.span && item.span != 24) {
      return { span: 24 / item.span * span }
    } else if (props.width >= 1200) {
      return { span }
    } else {
      return { span: 4 }
    }
  }
  const getItemWrapperCol = (item, layout) => {
    if (layout == 'hideLable') {
      return { span: 24 }
    } else if (layout == 'inline') {
      return { }
    } else if (item.wrapperCol) {
      return item.wrapperCol;
    } else if (!item.label) {
      return { span: 24 }
    } else if (props.width >= 1600) {
      return { span: 22 }
    } else if (props.width >= 1200) {
      return { span: 21 }
    } else {
      return { span: 20 }
    }
  }
  const setType = (item) => {
    if (typeof item.type == 'function') {
      return item.type({ data: formList.value, openType: props.openType, title: props.title });
    } else {
      return item.type;
    }
  }
  const setRequired = (item) => {
    if (typeof item.required == 'function') {
      return item.required({ data: formList.value, openType: props.openType, title: props.title });
    } else {
      return item.required === true;
    }
  }
  //设置formItem 的name
  const setFormItemName = (item) => {
    if (item.model && typeof (item.model) == 'string' && item.model.indexOf('.') != -1) {
      return item.model.split('.').map(v => isNaN(v) ? v : v * 1);
    } else if (item.model && typeof (item.model) == 'string') {
      return item.model;
    } else if (Array.isArray(item.model) && item.model.length) {
      return item.model[item.model.length - 1];
    } else {
      return '';
    }
  }
  //子组件ref合集
  const myNodes = ref([]);
  //表单ref对象
  const formRef = ref();
  const clearValidate = () => {
    formRef.value.clearValidate();
  };
  const resetFields = () => {
    formRef.value.resetFields();
  };
  const resetDataFun = () => {
    formList.value = JSON.parse(JSON.stringify(resetData));
  };
  //表单相关值
  const formList = ref({});
  let resetData = {};
  const showObj = ref({});//显示和隐藏
  const setShow = (type = 'add') => {
    let temp = {};
    props.form.map(v => {
      if (!v.model) {
        return
      }
      if (type !== 'approval' && type !== 'preview' && v.isApproval) {
        temp[v.model] = false;
      } else if (typeof v.show == 'function') {
        temp[v.model] = v.show;
      }else {
        temp[v.model] = v.show === false ? false : true;
      }
    });
    showObj.value = temp;
  }
  setShow();
  const disabled = ref({});//禁用
  const setDisabled = (type = 'add') => {
    let temp = {};
    props.form.map(v => {
      if (!v.model) {
        return
      }
      if (type === 'preview' || (type === 'approval' && !v.isApproval)) {
        temp[v.model] = true;
      } else {
        temp[v.model] = v.attrs && v.attrs.disabled ? v.attrs.disabled : false;
      }
    });
    disabled.value = temp;
  }
  setDisabled();
  //判断show子项是否是方法 是就运行
  const runShow = show => {
    if (typeof show == 'function') {
      return show({ data: formList.value, openType: openType.value, title: props.title });
    } else {
      return show;
    }
  }
  //初始化数据
  const openType = ref('');
  const initData = (data = {}, type = 'add') => {
    openType.value = type;
    myNodes.value = [];
    setShow(type);
    setDisabled(type);
    if (type === 'add') {
      const temp = {};
      props.form.map(v => {
        if (v.model && typeof (v.model) == 'string') {
          temp[v.model] = typeof v.default === 'function' ? v.default() : v.default;
        } else if (Array.isArray(v.model)) {
          v.model.map((name, i) => {
            if (Array.isArray(v.default)) {
              temp[name] = typeof v.default[i] === 'function' ? v.default[i]() : v.default[i];
            }
          });
        }
      });
      formList.value = Object.assign(temp, data);
    } else if (type === 'update' || type === 'preview' || type === 'approval') {
      formList.value = data || {};
    }
    resetData = JSON.parse(JSON.stringify(formList.value));
  }
  watch(() => props.show, v => {
    if (!v) {
      clearValidate();
      finish();
      //myNodes.value = [];
    }
  });

  const submitLoading = ref(false);
  const finish = (success = true) => {
    if (success) {
      Object.keys(formList.value).map(key => {
        formList.value[key] = undefined;
      });
      clearValidate();
    }
    submitLoading.value = false;
  };

  //提交失败回调
  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    //console.log('提交失败回调:', errorFields);
    //document.getElementsByClassName('my-modal-body')[0].scrollTop = document.getElementById(errorFields[0].name[0]).offsetTop;
    submitLoading.value = false;
    formRef.value.scrollToField(errorFields[0].name[0]);//同上一行操作
    message.error(errorFields[0].errors[0]);
  };
  //返回有变动的属性 用于新增和编辑
  const getSubmitData = data => {
    const newData = {};
    for (let key in data) {
      if (JSON.stringify(data[key]) !== JSON.stringify(resetData[key]) || props.whiteList.some(v => v === key)) {
        newData[key] = data[key];
      }
    }
    return newData;
  }
  //判断数据是否有修改
  const dateIsChange = data => {
    for (let key in data) {
      if (JSON.stringify(data[key]) !== JSON.stringify(resetData[key])) {
        return true;
      }
    }
    return false;
  }

  const submit = async (flag = true, check = true, checkDateIsChange = true) => {
    submitLoading.value = true;
    let names = props.form.filter(v => check && (setType(v) || v.slot) && (!v.model || runShow(showObj.value[v.model])) && setType(v)?.indexOf('my-upload') == -1 && (props.openType == 'approval' || (props.openType != 'approval' && !v.isApproval))).map(v => setFormItemName(v));
    if (names.length < props.form.length && myNodes.value.some(v => v.uploadReady)) {//小于则代表有上传组件 && 至少一个上传组件有可上传的文件
      let noUpload = await formRef.value.validate(names).then(() => {
        return true;
      }).catch(error => {
        onFinishFailed(error);
        return false;
      });
      if (!noUpload) {
        submitLoading.value = false;
        return;
      }
      //上传文件
      const res = [];
      for (let i = 0; i < myNodes.value.length; i++) {
        if (myNodes.value[i].uploadReady) {
          res.push(await myNodes.value[i].upload(false));
        }
      }
      if (!res.every(v => v)) {
        message.success('文件上传失败！ 请稍后重试');
        submitLoading.value = false;
        return;
      }
      message.success('文件上传成功！ 正在保存数据');
    }
    let validateNames = props.form.filter(v => check && (setType(v) || v.slot) && (!v.model || runShow(showObj.value[v.model])) && (props.openType == 'approval' || (props.openType != 'approval' && !v.isApproval))).map(v => setFormItemName(v));//非审批状态忽略审批项校验
    formRef.value.validate(validateNames).then(() => {
      //console.log('提交表单:', JSON.stringify(values));
      const submitData = flag ? formList.value : getSubmitData(formList.value);
      if (dateIsChange(formList.value) || !checkDateIsChange) {
        emits('submit', { data: submitData, type: check ? openType.value : 'draft', reset: finish });
      } else {
        finish(false);
        message.error('没有被修改的内容，请勿提交重复数据！');
      }
    }).catch(error => {
      onFinishFailed(error);
    });
  };
  const submitObj = (...arg) => {
    if (typeof arg[0] == 'object') {
      let param = arg[0];
      submit(param.flag || param.submitAllParams, param.check, param.checkDateIsChange);
    } else {
      submit(...arg);
    }
  }

  //向父级抛出的属性 方法
  defineExpose({
    initData,
    formList,
    show: showObj,
    disabled,
    submit: submitObj,
    submitLoading,
    resetFields,
    resetData: resetDataFun,
    clearValidate
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .myForm{
    flex-wrap: nowrap;
  }
  .formItemTitle {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background-color: rgba(24, 144, 255, 0.1);
    margin-right: -30px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }
  .columnIcon {
    display: inline-block;
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background-color: #1890ff;
    margin-right: 5px;
  }
  .formInline {
    flex: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .formInlineChild {
    margin-bottom: 10px;
  }
  .largeForm .my-input, .my-number, .largeForm .my-select, .largeForm .my-cascader {
    max-width: 250px;
  }
  .largeForm .ant-picker {
    width: 250px;
    max-width: 100%;
  }
  .largeForm .ant-picker-range {
    max-width: 400px;
  }
</style>
